@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .h60 {
    @apply text-[60px] leading-[118%] font-bold;
  }
  .h54 {
    @apply text-[54px] leading-[118%] font-bold;
  }
  .h48 {
    @apply xl:text-[48px] text-[28px] leading-[118%];
  }
  .h42 {
    @apply xl:text-[42px] text-[21px] leading-[118%] font-bold;
  }
  .h36 {
    @apply text-[36px] leading-[118%] font-bold;
  }
  .h32 {
    @apply text-[32px] leading-[118%] font-bold;
  }
  .h20 {
    @apply text-[20px] leading-[150%] font-bold;
  }
  .h16 {
    @apply text-[16px] leading-[150%] font-bold;
  }
  .h14 {
    @apply text-[14px] leading-[150%] font-bold;
  }
  .p20 {
    @apply xl:text-[20px] text-[12px]  leading-[150%];
  }
  .p18 {
    @apply text-[18px]  leading-[150%];
  }
  .p16 {
    @apply xl:text-[16px] text-[12px]  leading-[150%];
  }
  .p14 {
    @apply text-[14px]  leading-[150%];
  }
  .p12 {
    @apply text-[12px]  leading-[150%];
  }
  .font-pls_m {
    @apply font-medium;
  }
  .weight-b {
    @apply font-bold;
  }
  .weight-e {
    @apply font-extrabold;
  }
}

@layer utilities {
  body {
    margin: 0;
    font-family:pls_r, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #000;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  .textColor_white {
    background-image: -webkit-linear-gradient(
      180deg,
      #c5c5c5,
      #ffffff,
      #c5c5c5
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }
  .textColor_blue {
    background-image: -webkit-linear-gradient(180deg, #3705ff, #6d13ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }
  .black_trans_x {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 1)
    );
  }
  .black_trans_y {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    );
  }
  .line_trans {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1)
    );
    height: 100%;
    width: 1px;
  }
  .line_trans_r {
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1)
    );
    height: 100%;
    width: 1px;
  }
  .line_trans_x {
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1)
    );
    width: 100%;
    height: 1px;
  }
  .line_trans_xr {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1)
    );
    width: 100%;
    height: 1px;
  }
  .line_trans_both {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0)
    );
    width: 100%;
    height: 2px;
  }

  .black_blur {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(50px);
    box-shadow: 2px 4px 6px inset rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    padding: 8px;
  }
  .black_opacity {
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid #2c2c30;
    border-radius: 16px;
  }
  .bullet_box_bg {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    border: 1px solid #2c2c30;
    border-radius: 16px;
    padding: 8px;
  }
  .cus_pages {
    text-align: left;
    padding: 30px 10px;
    color: #fff;
    h1 {
      font-size: 34px;
      font-weight: bold;
      line-height: 150%;
    }
    h2 {
      font-size: 30px;
      font-weight: bold;
      line-height: 150%;
      display: flex;
      img {
        width: 50%;
      }
    }
    h3 {
      font-size: 28px;
      font-weight: bold;
      line-height: 150%;
    }
    h3 {
      font-size: 24px;
      font-weight: bold;
      line-height: 150%;
    }
    h4 {
      font-size: 20px;
      font-weight: bold;
      line-height: 150%;
    }
    h5 {
      font-size: 18px;
      font-weight: bold;
      line-height: 150%;
    }
  }
  @keyframes animate_opacity {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }
  .animate_opacity {
    animation: animate_opacity 1s ease-in-out forwards;
  }
}
