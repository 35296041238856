.ant-input {
  height: 56px;
  background: rgba($color: #fff, $alpha: 0.1);
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 4px inset #c0c0c0;
}
.form_box {
  height: 56px;
  background: rgba($color: #fff, $alpha: 0.1);
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 4px inset #c0c0c0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 10px;
}
.bullet_box_bg::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
  height: 100px;
}

/* 定制滚动条滑块样式 */
.bullet_box_bg::-webkit-scrollbar-thumb {
  background-color: rgba(#d9d9d9,0.3);
  border-radius: 6px;
  height: 100px;
}
