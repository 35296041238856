@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .block-icon {
    @apply w-[36px] h-[36px] bg-cover bg-no-repeat mx-[8px];
  }
  .two-img1 {
    @apply xl:w-[28.97vw] xl:mt-0 mt-[30px] w-[50vw] mx-auto;
  }
  .two-img2 {
    @apply xl:w-[18.22vw] xl:mt-0 mt-[30px] xl:opacity-60 w-[50vw] mx-auto;
  }
}
@layer utilities {
  @property --border-gradient-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  .three_table {
    width: 100%;
    font-size: 16px;
    color: #fff;
    text-align: left;
    thead {
      height: 56px;
      font-size: 16px;
      color: #93989a;
      font-weight: bold;
    }
    tbody td {
      height: 80px;
      border-bottom: 1px solid #000;
    }
  }
  .three_bg_white {
    backdrop-filter: blur(50px);
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    box-shadow: 2px 4px 16px inset rgba(#f8f8f8, 0.06);
  }
  .three_bg_white::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    mask: linear-gradient(
      to bottom,
      rgba(#000, 0.2) 0%,
      transparent 40%,
      transparent 60%,
      rgba(#000, 0.1) 100%
    );
  }

  .black-white-griad {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.9), rgba(#0e1218, 1));
  }
  .three_btn {
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
  }
  .three_btn::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 8px;
    border: 1px solid rgba(255,255,255,0.2);
    mask: linear-gradient(to bottom, rgba(#000, 0.2) 0%, rgba(#000, 0.1) 100%);
  }
  .animate_line {
    padding: 1px;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .animate_line::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    opacity: 0.5;
    background-image: conic-gradient(
      from var(--border-gradient-angle) at 50% 50%,
      transparent 0%,
      #e8ffc3 10%,
      transparent 20%,
      #536fff 50%,
      transparent 100%
    );
    animation: buttonBorderSpin 5s linear infinite 0ms;
  }
  @keyframes buttonBorderSpin {
    0% {
      --border-gradient-angle: 0turn;
    }

    100% {
      --border-gradient-angle: 1turn;
    }
  }
}
.swiper-wrapper {
  transition-timing-function: linear !important;
}
