.select {
  background: linear-gradient(
    to right,
    rgba(88, 64, 250, 0.53),
    rgba(255, 255, 255, 0)
  );
  position: relative;
}
.select::before {
  content: "";
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  height: 44px;
  width: 3px;
  background: linear-gradient(to bottom, #3705ff, #6d13ff);
}
