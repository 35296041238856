@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .navbar {
    @apply xl:bg-[rgba(255,255,255,10%)] xl:border-[1px] xl:border-[rgba(255,255,255,30%)] xl:rounded-[28px] xl:flex xl:justify-around cursor-pointer xl:relative absolute xl:top-0 top-[80px]  right-0;
  }
}

.one_level:hover {
  border-radius: 28px;
  background: -webkit-linear-gradient(180deg, #3705ff, #6d13ff);
  animation: animate_opacity 1s;
}
/*入场动画开始*/
.fade-enter {
  max-height: 0px;
}

/*入场动画过程*/
.fade-enter-active {
  max-height: 1000px;
  transition: max-height 1s linear;
}

/*入场动画结束*/
.fade-enter-done {
  max-height: 1000px;
  height: auto;
}
/*入场动画结束*/
.fade-exit {
  max-height: 0px;
}

/*入场动画过程*/
.fade-exit-active {
  max-height: 0px;
  transition: max-height 1s linear;
}
/*离场动画结束*/
.fade-exit-done {
  max-height: 0px;
}

/*入场动画开始*/
.arrow-enter {
  transform: rotate(180deg);
}

/*入场动画过程*/
.arrow-enter-active {
  transform: rotate(0deg);
  transition: transform 0.5s ease-in;
}

/*入场动画结束*/
.arrow-enter-done {
  transform: rotate(0deg);
}

/*离场动画开始*/
.arrow-exit {
  transform: rotate(180deg);
}
.farrow-exit-active {
  transform: rotate(180deg);
  transition: transform 0.5s ease-in;
}

@keyframes rotate30 {
  0% {
    transform: rotate(10deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}
.rotate30 {
  animation: rotate30 1s linear;
}
